'use strict';
import {div} from "../utils/makeElement";
import locale from "../utils/locale";

export default function makeHelpOnEmpty(){
  console.info("Empty playlist. Displaying beginner help")
  let overlay = div({
    className:"empty-playlist-helper",
    style:{
      position:"fixed",
      top:"40px",
      left:"0",
      width:"100%",
      height:"100%",
      background:"rgba(0,0,0,0.8)",
      zIndex:0,
      cursor:"default"
    },
    onclick:function(e){
      e.preventDefault();
      let parent = helper.parentNode;
      if (parent){
        parent.removeChild(helper);
      }
    }
  });
  let helper = div({style:{
    position:"relative",
    height:"100%",
    textAlign: "center",
    zIndex:1
  }},[
    div({
      style:{
        position:"absolute",
        top:"-41px",
        bottom:"-41px",
        borderBottom:"2px solid white",
        zIndex:1,
        color: "transparent"
      }
    },"_uploadbutton"),
    div({style:{
      position:"absolute",
      top:"46px",
      left:"-50px",
      width:"200px",
      color: "white",
      zIndex:1
    }},locale("helpEmptyPlaylistButton")),
    div({style:{
      position:"fixed",
      color:"white",
      top:"45%",
      left:0,
      right:0,
      width:"350px",
      maxWidth:"100vw",
      margin:"auto",
      zIndex:1,
    }},locale("helpEmptyPlaylist")),
    overlay
  ])
  var btn = document.querySelector(".playlist-links #playlist-link-upload")
  btn.parentNode.insertBefore(helper,btn);
  //document.body.appendChild(overlay);
}
