'use strict';

function getApp(){
  return import(/* webpackChunkName: "firebaseConfig" */ "../firebase.config.js")
  .then(({default: app})=> [app, app.firestore()]);
}

function mapDocs(results){
  return results.docs.map(doc=> assignDoc(doc));
}
function assignDoc(doc){
  return Object.assign(doc.data(),{id: doc.id});
}

export async function getAllScopes(){
  const [app, db] = await getApp()
  const scopesRef = db.collection("scopes")
  return scopesRef.get()
  .then(mapDocs)
  .then((scopes)=>{
    return Promise.all(scopes.map((scope)=>{
      let projectsRef = scopesRef.doc(scope.id).collection("projects");
      let projects = projectsRef.get()
      .then(mapDocs)
      return projects.then(projects=>Object.assign(scope, {projects: projects}));
      //Reduce data
    }));
  });
}

/* equivalent to getAllScopes but with streaming capability */
/* callback takes 2 arguments : event type and document */
export async function streamScopes(handler){
  const [app, db] = await getApp();
  const scopesRef = db.collection("scopes")
  return scopesRef.onSnapshot(function streamScopesSnapshot(scopesSnapshot){
    const changes = scopesSnapshot.docChanges();
    //console.log("Got snapshot :", changes, scopesSnapshot);
    changes.forEach(function forEachChange(changeDoc){
        const scope = assignDoc(changeDoc.doc);
        const eventType = changeDoc.type;
        if (eventType == "removed"){
          handler("removed", scope);
        }else if(eventType == "added"){
          let projectsRef = scopesRef.doc(scope.id).collection("projects");
          let projects = projectsRef.get().then(mapDocs)
          projects.then((projects)=>{
            handler("added", Object.assign(scope, {
              projects: projects,
              oldIndex: changeDoc.oldIndex,
              newIndex: changeDoc.newIndex
            }));
          });
        }else{
          console.warn("unhandled event type :", eventType, "for ", changeDoc);
        }
    });
    //*/


    // Call callback

  })
}
export async function deleteScope(scope){
  const settings = {/* your settings... */ timestampsInSnapshots: true};
  const [app, db] = await getApp();
  db.settings(settings);
  return db.collection("scopes").doc(scope).delete()
}

export async function fetchPlaylist(scope){
  const [app, db] = await getApp();
  scope = scope || window.clientScope;
  if(!scope) throw new Error("Expected scope to be a string. Got "+ typeof scope);
  db.settings({timestampsInSnapshots: true});
  const storage = app.storage();
  function getUrlIfExist(str){
    return (str)?storage.refFromURL(str).getDownloadURL():Promise.resolve("");
  }

  const projectsRef = db.collection("scopes").doc(scope).collection("projects")
  const projectsSnap = await projectsRef.get();
  const projectsDocs = await Promise.all(projectsSnap.docs.map(async (project)=>{
    const project_data = project.data();
    //FIXME : do this afterwards only for required URIs
    //console.log("refs :", project_data);
    const [download_url, thumb_url, formatThumb_url] = await Promise.all([
      getUrlIfExist(project_data.uri),
      getUrlIfExist(project_data.thumb),
      getUrlIfExist(project_data.formatThumb)
    ]);
    return Object.assign({scope: scope, id: project.id},project.data(), {
      uri:download_url,
      thumb: thumb_url,
      formatThumb: formatThumb_url
    });
  }));
  return projectsDocs;
}

export async function deleteProject(scope, id){
  const [app, db] = await getApp();
  const projectRef = db.collection("scopes").doc(scope).collection("projects").doc(id);
  return await projectRef.delete();
}

//Make a public download URL from a gs:// url or a https private reference
export async function getDownloadURL(url){
  const [app, db] =  await getApp();
  const storage = app.storage();
  return await (url)?storage.refFromURL(url).getDownloadURL(): Promise.resolve("");
}

export async function fetchauth(url, init={}){
  const [app] = await getApp();
  const auth = app.auth();
  return auth.currentUser.getIdToken(/*force refresh */false)
  .then((token) => fetch(url, Object.assign(
    init,
    {headers: Object.assign(
      ((init.headers)?init.headers:{}),
      {"Authorization":`Bearer ${token}`}
    )}
  )));
}

export async function getUserData(){
  const [app, db] = await getApp();
  const user =  app.auth().currentUser;
  if(!user) return Promise.reject(new Error("User not authenticated"));
  return db.collection("users").doc(user.uid);
}
