'use strict';
//Intelligent return button
import {icon} from "../utils/makeElement";


export default function makeReturn(){
  return icon("arrow-left",{
    style:{display:(window.location.pathname == "/")?"none":"initial"},
    onclick: intelligentReturn
  });
}
export function breakPath(p, scopeName){
  let re = new RegExp(`/(${scopeName})?/?([a-z]*)?`,"i");
  let parts = re.exec(p);
  let res = {};
  if (typeof parts[1] !== "undefined" ){
    res.scope = parts[1];
  }
  if (typeof parts[2] !== "undefined" ){
    res.page = parts[2];
  }
  return res;
}
function intelligentReturn(){
  let path = breakPath(window.location.pathname,window.clientScope);
  if (!path.page || path.page == "list"){
    window.location.pathname = `/`;
  }else{
    window.location.pathname = `/${path.scope}`;
  }
}
