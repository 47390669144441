'use strict';
import LangPicker from "./LangPicker";
import {notify} from "./events/notify";
import {setUp} from "./utils/login";
import {
  createTranscientNotification,
  createConsoleNotification,
  createLoggerNotification,
  createReportNotification
} from "./notifications";

import {login, logout} from "./utils/login";
import makeBurger from "./widgets/burger";
import makeReturn from "./widgets/return";
//import registerServiceWorker from "./registerServiceWorker";

// This import loads the firebase namespace along with all its type information.
// These imports load individual services into the firebase namespace.



/* BOOTSTRAP */
export default function globalInit(){
  'use strict';
  const initPromise = import(/* webpackChunkName: "firebaseConfig" */ "./firebase.config.js")
  //If video-controls doesn't exists, it will default to document.body as a parent
  createConsoleNotification();
  createReportNotification();
  //create persistant notifications if at least one widget exists
  const notificationWidgets = document.querySelectorAll(".notification-widget");
  if( 0 < notificationWidgets.length){
    console.log("set up persistent notifications");
    const persistentsNotifications = createLoggerNotification(notificationWidgets);
    document.body.appendChild(persistentsNotifications);
  }
  const errorParent = document.getElementById("video-controls")||document.body;
  const errorManager = createTranscientNotification();
  //Insert the error manager
  errorParent.appendChild(errorManager);

  //Wire the login/logout/register buttons
  for (let elem of document.querySelectorAll("[aria-label=login]")){
    elem.onclick = login;
  }
  for (let elem of document.querySelectorAll("[aria-label=register]")){
    elem.onclick = login;
  }
  for (let elem of document.querySelectorAll("[aria-label=logout]")){
    elem.onclick = logout;
  }
  return initPromise.then(function onInit({default: app}){
    //FIXME prefer conditional append to known anchors?
    //Always init lang picker
    window.langPicker = new LangPicker();
    let burger = document.getElementById("burger-menu");
    if (burger){
      burger.appendChild(makeBurger());
      //Make login only if there is a burger menu
      setUp(app);
    }
    let rBtn = document.getElementById("brand-image");
    if (rBtn){
      rBtn.appendChild(makeReturn());
    }

    // Service worker is autonomous starting from here...
    //registerServiceWorker();
    return app;
  });
}
