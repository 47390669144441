'use strict';
import {fetchPlaylist, deleteProject, getDownloadURL} from "./utils/queries";
import makeHelpOnEmpty from "./widgets/helpOnEmpty";
import uploadHandler from "./widgets/uploadHandler";
import {listenPlaylist} from "./events/playlist";
import {listenLogin, isAllowed} from "./events/login";
import {notify, notifyPersistent} from "./events/notify";

import card from "./widgets/card";
import {empty} from "./utils/makeElement";
import init from "./main";

// Module global
let list;
const scope = window.clientScope;

//Playlist event handler
function onPlaylist(container, playlist){
  if(!playlist) return;
  list = playlist;
  empty(container);
  if (list.length == 0 && isAllowed(scope)){
    //Will auto-append children to body. Safe to call multiple times
    makeHelpOnEmpty();
    return
  }
  const cards = playlist.map(function mapPlaylistItem(item, idx){
    // item : {scope: "holusion", format: "pixel", formats: [{state: "DONE", uri: "...", name: "foo"}]}
    //console.log("Create card for : ",item);
    let opts = {
      controls: isAllowed(scope),
      title: item.id,
      href: `/${item.scope}/view?v=${encodeURIComponent(item.id)}`,
      ondelete:function handleDelete(){
        if(!confirm(`Delete ${item.id}?`)) return;
        notify("info",`deleting ${item.id}...`);
        deleteProject(scope, item.id).then(function(){
          notify("info", `${item.id} deleted`);
          window.location.reload();
        }).catch((e)=>{
          console.error(e);
          notifyPersistent("error", `Failed to delete ${item.id}`);
        })
      },
      ondownloadURL: getDownloadURL(item["uri"])
    };
    if(item["thumb-512"]){
      //This is a promise to a media URL
      opts.image = getDownloadURL(item["thumb-512"]);
    }
    return card(opts);
  })
  cards.forEach(function(el){
    container.appendChild(el);
  });
};

/*
 * BOOTSTRAP
 * Initialize menu
 * Also initialize playlist if a "#playlist" is found
 * Maintain playlist state (enable controls) after user login
 */
init().then(function listAppInit(app){
  'use strict';
  const button = document.querySelector("#playlist-link-upload");
  //We listen for playlist event or login event. Whichever happen first
  listenLogin(function(e){
    const user = e.detail;
    if(!user || !isAllowed(scope)){
      button? button.style.display = "none":true;
      return;
    }
    if(list && list.length == 0){
      makeHelpOnEmpty();
    }

    button? button.style.display = "block": true;
    onPlaylist(list);
  });

  const container = document.querySelector("#playlist");
  if (container){
    fetchPlaylist(scope)
    .catch(function catchFetchError(e){
      console.error("Fetch playlist error", e);
      return [];
    })
    .then(onPlaylist.bind(null, container));
  }
})
