'use strict';
import {icon, span, makeElement} from "../utils/makeElement";
/* Generic button factory
 * Available options :
  {
  icon: string <icon_name>,
  text: string <button_text>,
  raised: bool <is_raised>,
  compact: bool <is_compact>,
  accent: bool <accent_color>,
  onclick: func <click handler>,
  href: string <link>,
  isLink: bool <is a A element>
}

*/
export function button(opts){
  let children = [];
  if (opts.icon){
    children.push(icon(opts.icon, {fill:"#FFFFFF", className:"button-icon"}))
  }
  if(opts.text){
    children.push(span(opts.text));
  }
  return makeElement((opts.href || opts.isLink)?"a":"button",Object.assign({
    id: opts.id,
    className: "button"
    + ((opts.accent)? " button-accent":"")
    + ((opts.raised)? " button-raised":"")
    + ((opts.compact)?" button-compact":""),
    onclick : opts.onclick,
    title: opts.title,
  },(opts.href)?{href:opts.href}: {} /*otherwise there's a warning on empty href for buttons*/),
  children)
}

export function miniButton(opts){
  return button(Object.assign({isLink:true, compact: true}, opts));
}
