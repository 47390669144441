'use strict';
var internalPlaylistState = [];
var windowObject = (typeof window !== "undefined")? window: {};

//To mock window object.
export function useWindow(w){windowObject = w};

export function dispatchPlaylist(newState){
  internalPlaylistState = (Array.isArray(newState))?newState:[];
  document.dispatchEvent(new CustomEvent("playlist",{detail:internalPlaylistState}));
}

export function  getPlaylist(){
  return internalPlaylistState;
}

export function listenPlaylist(fn){
  document.addEventListener("playlist",fn);
  return document.removeEventListener.bind(document,"playlist",fn);
}
