'use strict';
import {listenNotify} from "./events/notify";
import {div, span} from "./utils/makeElement";
import getVersionID from "./utils/version";

import {miniButton} from "./widgets/button"

import {gaEvent} from "./events/analytics";


export function createTranscientNotification(){
  var parent = div({
    className: "notify-box",
  })
  listenNotify(function onNotifyTranscient(e){
    const severity = e.detail.severity;
    const message = e.detail.message.join("");
    const isPersistent = e.detail.persist;
    const log_box = div({
      className: "log-line"+((severity =="info")?"":" error"),
    },[
      span(message),
      miniButton({
        icon: "delete",
         onclick:function(){
           const log_line_node = this.parentNode;
           if(log_line_node && log_line_node.parentNode){
             log_line_node.parentNode.removeChild(log_line_node);
           }
         }
      })
    ]);
    // check children count to prevent overflow
    if( 3 < parent.childNodes.length){
      parent.removeChild(parent.firstChild);
    }
    parent.appendChild(log_box);
    if(!isPersistent){
      setTimeout(function onNotifyTranscientTimeout(){
        if(log_box.parentNode) {
          log_box.parentNode.removeChild(log_box);
        }
      },2500)
    }

  })
  return parent;
}

export function createConsoleNotification(){
  return listenNotify(function onNotifyConsole(e){
    const severity = e.detail.severity;
    const message = e.detail.message;
    switch(severity){
      case "info":
        console.info(...message);
        break;
      case "warn":
        console.warn(...message);
        break;
      case "error":
        console.error(...message);
        break;
      default:
        console.log("Unknown severity : %s for message %s",severity, message);
        return; //We do not push bad messages in queue
    }
  })
}

//Telemetry reporter
export function createReportNotification(){
  return listenNotify(function onNotifyReport(e){
    const severity = e.detail.severity;
    const message = e.detail.message.join(", ");
    switch(severity){
      case "info":
        break;
      case "warn":
      case "error":
        gaEvent(severity, "exception", {description: message, fatal: false});
    }
  })
}

export function createLoggerNotification(widgets){
  const log = [];
  const version = getVersionID();
  console.log("Version ID : ",version);
  const elem = div({});
  const parent = div({
    className:"log-box hidden",
    style:{
      "position":"fixed",
      "background":"white",
      "color":"black",
      "top":0,
      "margin":"auto",
      "padding":"20px",
    }
  },[version, elem]);
  for (let i=0; i< widgets.length; i++) {
    widgets[i].onclick = function(){
      parent.classList.toggle("hidden");
    }
    widgets[i].style.display="none";
  }
  listenNotify(function onNotifyLogger(e){
    //use JS timestamps in ms.
    let l = {
      timestamp:Date.now(),
      severity: e.detail.severity,
      message: e.detail.message
    };
    //there is no prependChild() in DOM so the last child is newest.
    log.push(l);
    elem.appendChild(div(`${new Date(l.timestamp)} : [${l.severity}] ${l.message}`));
    //keep logs for an hour or to a max length of 10 messages
     while(
       log[0] &&
       (log[0].timestamp < Date.now()-1000*60*60 || 10 < log.length )
     ){
       //no check on chiold existence because log[0] exists and it should always be in sync.
       elem.removeChild(elem.firstChild);
       log.shift();
     }
     for (let i=0; i< widgets.length; i++) {
       widgets[i].style.display="";
     }
  });
  return parent;
}
