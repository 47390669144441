'use strict';
/* send the localized string corresponding to "key" */

export default function locale(key){
  if (! window.LOCALE_DATA){
    console.warn("No LOCALE_DATA available");
    return null;
  }else{
    return window.LOCALE_DATA[key];
  }
}
