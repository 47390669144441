'use strict';


window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}


// See : https://developers.google.com/analytics/devguides/collection/gtagjs/events#send-events
// Has been changed a bit to be more concise and readable. eg:
//  gaEvent("Video", "play", "video/unique/id");
//  gaEvent("Log", "warn", "Error message");
// Can also provide other args using an object :
// gaEvent("any_category", "any_name", {event_label: "Error message", metric_name: "metric_value"});
export function gaEvent(category, action, labelOrOpts){
  if(typeof labelOrOpts === "string"){ //is label
    labelOrOpts = {event_label:labelOrOpts};
  }
  //Notify google-analytics custom event
  gtag('event', action, Object.assign(
    {event_category: category},
    labelOrOpts
  ));
}

export function registerGa(doTrack){
  if(doTrack === false) window['ga-disable-UA-48469340-3'] = true;
  gtag('js', new Date());
  gtag('config', 'UA-48469340-3');
}
