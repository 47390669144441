'use strict';

import {div, a} from "../utils/makeElement";
import {miniButton} from "./button"

// Make a card HTML element
// Supported options are :
// - title *string*
// - href *string*
// - style *object*
// - image *string* or *Promise -> string* to an image URL
// - ondownloadURL *string* or *Promise -> string* to a media download URL
// - controls *bool*
// - custom handlers for controls (if opts.controls == true)
//  - ondelete *function*

export default function card(opts){
  opts = Object.assign({
    style:{}
  },opts);

  let ln = a({
    className: "item-cover",
    href:opts.href,
    style: opts.style
  })

  let el = div({className:"card-square"},[
    ln,
    div({title: opts.title, className:"item-title"}, opts.title)
  ]);

  // Always convert opts.image to a promise to support async URL resolutions
  if(opts.image){
    Promise.resolve(opts.image).then(function onCardImageUrl(url){
      if(url) ln.style.background = `url('${url}') center/cover no-repeat`
    });
  }
  if(opts.controls){
    el.appendChild(cardControls(opts));
  }
  return div({
    className:"playlist-item",
    style: {}
  }, el);
}

export function cardControls(opts){
  const el =  div({
    className:"item-controls"
  }, [
    miniButton({
      icon: "delete",
       onclick:opts.ondelete
    })
  ]);
  //Add defered elements
  if(opts.ondownloadURL ){
    Promise.resolve(opts.ondownloadURL).then((url)=>{
      let download_link = miniButton({
        icon: "download",
        isLink: true,
        href: url, //a direct link to download media
        title: "download video"
      });
      const fullname = url.split("/").slice(-1)[0].split("?")[0].split("%2F").slice(1).join("_");
      //remove first part  (store/)
      download_link.download = decodeURIComponent(fullname);
      download_link.target = "_blank";
      el.appendChild(download_link)
    })
  }
  return el;
}
