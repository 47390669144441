//Token storage string.

const ops = {
  login:true,
  logout:true,
  register:true,
  submit:true,
}
let saved_state = null;
//Valid states are : login, logout, try, register
export function dispatchLogin(user){
  saved_state = user;
  document.dispatchEvent(new CustomEvent("login",{detail:user}));
}

//Returns an event. in `event.detail` will be the currently logged user's infos
export function listenLogin(fn){
  document.addEventListener("login",fn);
  return document.removeEventListener.bind(document,"login",fn);
}

export function isConnected() {
  return saved_state;
}

export function isAllowed(scope){
  if (typeof scope !== "string") throw new Error("isAllowed(scope) : expected scope to be a string. Got : "+ typeof scope);
  //console.log("Check if ", (saved_state)?saved_state.claims:null, "is allowed on :",scope);
  return saved_state
      && saved_state.claims
      && ((
        saved_state.claims.scopes
        && saved_state.claims.scopes.indexOf(scope) !== -1
        )
        ||(
          saved_state.claims.admin === true
        )
      );
}
