'use strict';


export default function getVersionID(){
  return getHashName(document);
}

//Isolated testable export
export function getHashName(doc){
  let scripts = doc.getElementsByTagName("SCRIPT");
  for (let i=0;i < scripts.length;i++){
    //return the first matching string found
    let m = /\/\w*.([a-f0-9]{20})\.js$/.exec(scripts[i].src);
    if(m && m[1]){
      return m[1];
    }
  }
}
