'use strict';
const documentObject = (typeof document !== "undefined")? document: {addEventListener:function(){}};


//Severity can be one of :
// info
// warn
// error
export function notify(severity, ...messages ){
  dispatchNotify(severity, false, ...messages);
}

export function notifyPersistent(severity, ...messages){
  dispatchNotify(severity, true, ...messages);
}

function dispatchNotify(severity, persist, ...messages){
  documentObject.dispatchEvent(new CustomEvent("notify",{detail:{
    severity:severity,
    persist: persist,
    message: messages //An array with arguments
  }}));
}

export function listenNotify(fn){
  document.addEventListener("notify",fn);
  return document.removeEventListener.bind(document,"notify",fn);
}
