'use strict';
const attributeExceptions = [
  `role`,
  `for`
];

function appendText(el, text) {
  const textNode = document.createTextNode(text);
  el.appendChild(textNode);
}

function append(el, textOrPropsOrChild){
  if (Array.isArray(textOrPropsOrChild)) {
    appendArray(el, textOrPropsOrChild);
  } else if (textOrPropsOrChild instanceof window.Element) {
    el.appendChild(textOrPropsOrChild);
  } else if (typeof textOrPropsOrChild === `string`) {
    appendText(el, textOrPropsOrChild);
  } else if (typeof textOrPropsOrChild === `object`) {
    applyProps(el, textOrPropsOrChild);
  }
}

function appendArray(el, children) {
  children.forEach((child) => {
    if (Array.isArray(child)) {
      appendArray(el, child);
    } else if (child instanceof window.Element) {
      el.appendChild(child);
    } else if (typeof child === `string`) {
      appendText(el, child);
    }
  });
}

function setStyles(el, styles) {
  if (!styles) {
    el.removeAttribute(`styles`);
    return;
  }

  Object.keys(styles).forEach((styleName) => {
    if (styleName in el.style) {
      el.style[styleName] = styles[styleName]; // eslint-disable-line no-param-reassign
    } else {
      console.warn(`${styleName} is not a valid style for a <${el.tagName.toLowerCase()}>`);
    }
  });
}
function applyProps(el,props){
  Object.keys(props).forEach((propName) => {
    if (propName in el || attributeExceptions.includes(propName)) {
      const value = props[propName];

      if (propName === `style`) {
        setStyles(el, value);
      } else if(propName === `dataset` && typeof value === "object" ){
        Object.keys(value).forEach((key)=> {el.dataset[key] = value[key]});
      } else if (value) {
        el[propName] = value;
      }
    } else {
      console.warn(`${propName} is not a valid property of a <${el.tagName.toLowerCase()}>`);
    }
  });
}
export default function makeElement(type, textOrPropsOrChild, ...otherChildren) {
  const el = document.createElement(type);

  append(el, textOrPropsOrChild);

  if (otherChildren) appendArray(el, otherChildren);

  return el;
}

//less flexible than makeElement but can have children
function icon(name, props){
  const el = document.createElement("span");
  el.innerHTML = `<svg fill="#fff" viewBox="0 0 24 24">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-${name}"></use>
      </svg>`
  let icn = el.firstChild
  if (typeof props == "object"){
    Object.keys(props).forEach(function(prop) {
      if(prop == "className"){
        icn.setAttributeNS(null, "class", props[prop]);
      }else if(prop == "style"){
        setStyles(el.firstChild,props.style);
      }else{
        icn.setAttributeNS(null, prop, props[prop]);
      }
    });
  }
  return icn;
}

//Shorthand to empty an element. Faster than innerHTML="";
function empty(el){
  while (el.firstChild) {
    el.removeChild(el.firstChild);
  }
}

export {makeElement, icon, empty};
export const div = (...args) => makeElement(`div`, ...args);
export const a = (...args) => makeElement(`a`, ...args);
export const li = (...args) => makeElement(`li`, ...args);
export const ul = (...args) => makeElement(`ul`, ...args);
export const span = (...args) => makeElement(`span`, ...args);
export const button = (...args) => makeElement(`button`,...args);
export const input = (...args) => makeElement(`input`,...args);
export const p = (...args) => makeElement(`p`,...args);
