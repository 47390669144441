'use strict';
import {listenLogin, dispatchLogin} from "../events/login";

const confModule = import(
  /* webpackChunkName: "firebaseConfig" */
  "../firebase.config.js");
const uimodule = import("firebaseui");


const uiRef = Promise.all([
  uimodule,
  confModule
]).then(([firebaseui, {default: app}])=>{
  const auth = app.auth();
  return new firebaseui.auth.AuthUI(auth);
});


//FIXME maybe not each time? Or at least compare claims
confModule.then(({default: app})=>{
  const auth = app.auth();
  auth.onAuthStateChanged(function(user){
    dispatchLogin(user);
    if (user){
      user.getIdToken(/*force refresh ?*/ true).then(function(token){
        //Use the token to set httpOnly cookie for further authentication

        return fetch(`/users/${user.uid}/claims`, {headers: {"Authorization":`Bearer ${token}`}})
        .then(res => {
          if(res.ok){
            return res.json();
          }else{
            return res.text()
            .then((txt)=> {throw new Error(`Failed to get claims : ${txt}`)});
          }
        })
        .then(function(claims){
          if(claims){
            dispatchLogin(Object.assign({}, user, {claims: claims, token: token}))
          }
        })
        .catch(function(e){
          console.error("Failed to get user claims : ", e);
          dispatchLogin(Object.assign({}, user, {token: token}));
        })
      })
    }
  });
})

export function setUp(app){
  let container = document.querySelector("#firebaseui-auth-container");
  if (!container){
    return console.warn("No authentication container on page : #firebaseui-auth-container not found");
  }

  container.onclick = function(e){
    //click to cancel
    console.log(e.target);
    if(e.target == container) uiRef.then(i => i.reset());
  }
}

export function login(){
  return Promise.all( [uiRef, confModule, uimodule] )
  .then(([ui, firebaseConf, firebaseui])=>{
    console.log(firebaseConf)
    const firebase = firebaseConf.getModule();

    /*
    * FIREBASE UI configuration *
    * https://github.com/firebase/firebaseui-web#configuration
    */
    let uiconfig = {
      signinSuccessUrl: window.location,
      signInFlow: 'popup',
      tosUrl: "https://pixel.holusion.com/about",
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      ],
      //FIXME enable one-tap-signup
      // https://github.com/firebase/firebaseui-web/blob/master/README.md#credential-helper
      credentialHelper: firebaseui.auth.CredentialHelper.NONE
    }
    ui.start("#firebaseui-auth-container", uiconfig);
  });
}

export function logout(){
  confModule.then(({default: app})=>{
    const auth = app.auth();
    return auth.signOut().then(function(){console.log("signed out")});
  })
}
