'use strict';
import {notify, notifyPersistent} from "../events/notify";
import {div, span, button, empty, icon, input, makeElement} from "../utils/makeElement";
// run an upload operation
// FIXME needs a cancel / resume action handler
// Need a javascript File object created from an <input> element
export default function uploadHandler(file, destRef, meta){
  //Create elements
  const txt = span({
    className:"upload-status",
    style:{
      lineHeight:"2.25rem"
    }
  });
  const spinner = span({
    className:"spinner",
    style:{
      display: "inline",
      margin: 0,
      width:"18px",
      height:"18px",
      lineHeight:"2.25rem"
    }
  });

  const el = div({className:"progress"},[
    spinner,
    txt
  ]);
  function end(success){
    if (success){
      el.replaceChild(icon("ok", {
        className:"button-icon",
        width:"18px",
        style:{fill:"#4CAF50"}
      }), spinner)
    }else{
      el.replaceChild(icon("fail", {
        className:"button-icon",
        width:"18px",
        style:{fill:"#F44336"}
      }), spinner)
    }
  }
  let task = destRef.put(file, meta);
  task.on('state_changed',(evt)=>{ //progress callback
    let percentage = Math.round((evt.bytesTransferred * 100) / evt.totalBytes);
    //console.log("Progress event (%d%%)",percentage,evt);
    txt.textContent = `Progresssion : ${percentage}%`;
  }, (e)=>{ //error callback
    if (e.code === "storage/unknown" && e.serverResponse){
      let msg = (typeof e.serverResponse === "string")? JSON.parse(e.serverResponse):e.serverResponse;
      //It's a gcloud storage error
      //console.log("Storage error : ",msg);
      if (msg.error.code === 400){
        notifyPersistent("error", "You don't have permission to upload files here");
      }else{
        notifyPersistent("error", "Upload failed : "+msg.error.message);
      }
    }else{
      console.warn("Internal error", e);
      notifyPersistent("error", "Error uploading file : "+e.message);
    }
    //Either case, we're finished immediately
    end(false);
  },()=>{ //DONE callback
    notify("info","Uploaded. Please wait for composition")
    txt.textContent = file.name + " uploaded. Please wait for composition...";
    setTimeout(function(){
      txt.textContent = file.name + " Done";
      end(true);
    },5400);
  });

  //return main element for insertion
  return el;
}
