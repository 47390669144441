'use strict';
import {icon, ul, li, span, a, div, makeElement} from "../utils/makeElement";
import {dispatchLogin, listenLogin, isConnected} from "../events/login";
import {login, logout} from "../utils/login";

export default function makeBurger(){
  let menu;
  let update = function(user){
    let oldMenu = menu;
    menu = burger();
    //TODO Make a diff of some sort?
    if (oldMenu && oldMenu.parentNode){
      oldMenu.parentNode.replaceChild(menu, oldMenu);
    }
    return menu;
  }
  listenLogin(update);
  return update();
}

function burger(update){
  //<home> button is always present
  let children = [
    btn("home","home","/list"), //TODO go to user's home!
  ];
  //We add items depending on app state
  const user = isConnected()
  if (user){
    children.push(btn("login","logout",function(){
      logout();
    }));

    children.push(btn("view","profile",`/users/${user.uid}/profile`));
  }else{
    children.push(btn("login","login",function(){
      login();
    }));
    children.push(btn("signin","register",function(){
      login();
    }));
  }
  let menuItems = ul({
    id: "nav-links",
  },children);
  let portrait_url = ((user && user.photoURL)? user.photoURL: "/static/img/default_portrait.png")
  //Make the menu element.
  return div({
    id:"navbar-burger",
    className: "navbar-menu",
    style:{width:"100%", display: "flex"},
  },[
    a({ //This is to display profile informations
      style:{
        display: (user?"inline-flex":"none"),
      },
      href: (user? `/users/${user.uid}/profile`: null)
    }, [
      span({className:"display-name"},(user)?user.displayName:""),
      span( {
        style:{
          borderRadius: "50%",
          display: "inline-block",
          margin: "-1px",
          overflow: "hidden",
          height: "32px",
          width: "32px",
          backgroundSize: "33px 33px",
          backgroundImage: `url(${portrait_url})`,
        }
      }),
    ]),
    menuItems,
    div({
      id:"nav-toggle",
      style:{width:"30px",height:"30px", cursor:"pointer"},
      onclick:function menuToggle(){
        menuItems.classList.toggle("active");
      },
    }, icon("more",{style:{}})),

  ])
}


function btn(iconName,text, onclickOrHref){
  let children = [
    icon(iconName, {style:{fill:"#053241",width:"24px",height:"24px"}}),
    span(text),
  ];
  let opts = {};
  if (typeof onclickOrHref === "string"){
    children = a({href:onclickOrHref},children);
  }else if (typeof onclickOrHref === "function"){
    opts.onclick = onclickOrHref;
    children= a(children);
  }else{
    console.warn("menu item with no handler : [%s] %s",icon, text);
  }
  return li(opts,children);
}
