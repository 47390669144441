'use strict';


export default class LangPicker {
  constructor(){
    const exdays = 150;//cookies storage time
    var languages = document.querySelectorAll("#lang-selector>A")
    function setLanguage(e){
      var d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      var expires = "expires="+ d.toUTCString();
      document.cookie = "lang=" + e.target.id + ";" + expires;
    }
    for (var i=0, l = languages.length;i<l;i++){
      languages[i].onclick= setLanguage;
      languages[i].href = window.location.href;
    }
  }
}
